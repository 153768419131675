<template>
  <div style="width: 100%" id="infoStream">
    <v-card color="white" class="border-radius-sm mt-2">
      <v-card-title
        class="d-flex justify-space-between align-center px-0 py-0"
        style="position: sticky; top: 56px; z-index: 1; background: #fff"
        ><v-btn-toggle borderless>
          <v-btn text class="font-weight-bold text-h6">信息流</v-btn>
        </v-btn-toggle>
        <v-menu offset-y v-if="stockId == '' && tagId == ''">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              自选管理

              <v-icon class="pl-2 text-black">{{
                show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click.stop="showCollect(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-card-text
        style="position: sticky; top: 104px; z-index: 1; background-color: #fff"
        class="pb-0"
      >
        <v-row no-gutters>
          <v-btn-toggle
            dense
            borderless
            v-model="tab"
            @change="changeTab"
            mandatory
          >
            <v-btn
              text
              :disabled="tableLoading"
              v-if="(stockId == '' && tagId == '') || tagId"
              >关注的标签</v-btn
            >

            <v-btn
              text
              :disabled="tableLoading"
              v-if="(stockId == '' && tagId == '') || stockId"
              >关注的个股</v-btn
            >
          </v-btn-toggle>
          <v-row
            class="ml-4 no-getters"
            v-if="(stockId == '' && tagId == '') || tagId"
          >
            <v-switch
              color="info"
              v-model="no_race"
              :label="`未分类`"
              @change="classChange"
            ></v-switch>
            <div style="width: 300px" class="ml-8 d-flex align-center">
              <v-text-field
                v-model="search"
                label="搜索"
                append-icon="fa-search"
                dense
                @click:append="$refs.scroll.trigger('refresh')"
              >
                <!-- <v-icon slot="append" color="black"> fa-search </v-icon> -->
              </v-text-field>
            </div>
          </v-row>
        </v-row>

        <v-row no-gutters class="mt-2">
          <v-btn-toggle
            dense
            borderless
            v-model="btnTab"
            @change="changeBtnTab"
            active-class="v-zc-item--active"
            class="zc-toggle"
            mandatory
          >
            <v-btn
              text
              class="text-caption"
              :disabled="tableLoading"
              v-for="(btn, index) in btnsMenu"
              :key="index"
              >{{ btn }}</v-btn
            >
          </v-btn-toggle>
        </v-row>
      </v-card-text>
      <vue-scroll
        ref="scroll"
        :refreshStart="refreshStart"
        :loadStart="loadStart"
        :noData="noData"
        class="timeline-scroll"
      >
        <v-card-text class="pt-0">
          <v-list class="pt-0" style="min-height: 100px" ref="listBox">
            <v-list-item-group>
              <div
                v-if="infoList.length == 0 && !tableLoading"
                style="
                  width: 100%;
                  height: calc(100% - 50px);
                  flex-direction: column;
                "
                class="d-flex justify-center align-center"
              >
                <img src="@/assets/img/empty.png" />
                <div style="color: #bfcee0">请先收藏个股或标签</div>
              </div>

              <template v-for="(str, index) in infoList">
                <v-list-item :key="index.id" class="px-0">
                  <comments
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '点评'"
                  ></comments>
                  <minutes
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '外部会议'"
                  ></minutes>
                  <announcements
                    :str="str"
                    :tab="tab"
                    v-if="
                      str.type == '纪要' ||
                      str.type == '公告' ||
                      str.type == '研报' ||
                      str.type == '微信' ||
                      str.type == '观点'
                    "
                  ></announcements>
                  <hudongyi
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '互动易'"
                  ></hudongyi>
                  <library
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '骊阅文件库'"
                  ></library>
                  <ratings
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '骊阅评分'"
                  ></ratings>
                  <research
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '骊阅调研'"
                  ></research>
                  <research-reports
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '外部研报'"
                  ></research-reports>
                  <viewpoints
                    :str="str"
                    :tab="tab"
                    v-if="str.type == '骊阅标签观点'"
                  ></viewpoints>
                  <news :str="str" :tab="tab" v-if="str.type == '资讯'"></news>
                </v-list-item>

                <v-divider
                  v-if="infoList.length && index < infoList.length - 1"
                  :key="`divider-${index}`"
                  light
                  style="opacity: 0.5"
                ></v-divider>
              </template>
              <!-- <v-skeleton-loader
                v-if="tableLoading && page == 1"
                v-bind="attrs"
                type="article, actions"
              ></v-skeleton-loader> -->
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </vue-scroll>
    </v-card>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="800"
        scrollable
        max-width="800px"
        @click:outside="
          dialog = false;
          dialog1 = false;
          dialog2 = false;
        "
      >
        <showCollectStock
          v-if="dialog1"
          :show="dialog1"
          @close="closeStock"
          @closeW="
            dialog = false;
            dialog1 = false;
            dialog2 = false;
          "
        />
        <showCollectRace
          v-if="dialog2"
          :show="dialog2"
          @close="closeRace"
          @closeW="
            dialog = false;
            dialog1 = false;
            dialog2 = false;
          "
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import showCollectStock from "./showCollectStock.vue";
import showCollectRace from "./showCollectRace.vue";
import comments from "./TypeComponents/comments.vue";
import minutes from "./TypeComponents/minutes.vue";
import research from "./TypeComponents/research.vue";
import announcements from "./TypeComponents/announcements.vue";
import hudongyi from "./TypeComponents/hudongyi.vue";
import library from "./TypeComponents/library.vue";
import ratings from "./TypeComponents/ratings.vue";
import researchReports from "./TypeComponents/researchReports.vue";
import VueScroll from "@/components/pull-to-refresh/vue-scroll.vue";
import viewpoints from "./TypeComponents/viewpoints.vue";
import news from "./TypeComponents/news.vue";
import User from "@/api/user";
export default {
  name: "HomeContent",
  components: {
    showCollectStock,
    showCollectRace,
    comments,
    minutes,
    research,
    announcements,
    hudongyi,
    library,
    ratings,
    researchReports,
    viewpoints,
    news,
    VueScroll,
  },
  data() {
    return {
      tableLoading: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      infoList: [],
      btnsMenu: [
        "全部",
        "纪要",
        "外部研报",
        "外部会议",
        "研报",
        "骊阅标签观点",
        "骊阅文件库",
        "点评",
        "互动易",
        "资讯",
        "微信",
        "观点",
        "公告",
      ],
      tab: 0,
      btnTab: 0,
      limit: 15,
      show: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      items: [
        { title: "自选个股", value: 0 },
        { title: "自选标签", value: 1 },
      ],
      page: 1,
      noData: true,
      scrollTopHeight: 0,
      total: 0,
      countOfRender: 0,
      data: [],
      once: 50,
      no_race: "",
      search: "",
    };
  },
  methods: {
    getInfoList(isreset, done) {
      this.tableLoading = true;
      if (isreset) {
        this.page = 1;
        this.countOfRender = 0;
      } else {
        this.page++;
      }

      let formData = {
        tab_top: this.tab ? "stock" : "race",
        tab_bottom:
          this.btnsMenu[this.btnTab] == "全部"
            ? "所有"
            : this.btnsMenu[this.btnTab],
        stock_id: this.stockId,
        race_id: this.tagId,
        page: this.page,
        no_race: this.no_race,
        search: this.search,
      };
      User.getInfoList(formData)
        .then((res) => {
          if (res.data.length == 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
          if (isreset) {
            this.infoList = res.data;
            this.tableLoading = false;
            if (done) {
              done();
            }
          } else {
            // this.infoList = this.infoList.concat(res.data);
            this.countOfRender = 0;
            this.total = res.data.length;
            this.data = res.data;
            this.infoList = this.infoList.concat(res.data);
            // this.loop(done);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableLoading = false;

          if (done) {
            done();
          }
        });
    },
    showCollect(item) {
      this.show = false;
      this.dialog = true;
      if (item.value == 0) {
        this.dialog1 = true;
        this.dialog2 = false;
      } else {
        this.dialog2 = true;
        this.dialog1 = false;
      }
      console.log();
    },
    closeStock() {
      this.dialog = false;
      this.dialog1 = false;
      // this.$nextTick(() => {
      this.$refs.scroll.trigger("refresh");
      // });
    },
    closeRace() {
      this.dialog = false;
      this.dialog2 = false;
      // this.$nextTick(() => {
      this.$refs.scroll.trigger("refresh");
      // });
    },
    changeTab(event) {
      console.log(event);
      if (event == 0) {
        this.btnsMenu = [
          "全部",
          "纪要",
          "研报",
          "外部研报",
          "外部会议",
          "骊阅标签观点",
          "骊阅文件库",
          "点评",
          "互动易",
          "资讯",
          "微信",
          "观点",
          "公告",
        ];
      } else if (event == 1) {
        this.btnsMenu = [
          "全部",
          "纪要",
          "研报",
          "外部研报",
          "外部会议",
          "骊阅评分",
          "骊阅调研",
          "骊阅文件库",
          "点评",
          "互动易",
          "公告",
          "资讯",
          "微信",
          "观点",
        ];
      } else {
        this.btnsMenu = [];
      }

      this.btnTab = 0;

      // this.$nextTick(() => {
      this.$refs.scroll.trigger("refresh");
      // });
    },
    changeBtnTab(event) {
      console.log(event);
      // this.$nextTick(() => {
      this.$refs.scroll.trigger("refresh");
      // });
    },
    scrollEvent(event) {
      console.log(event);
    },
    // 刷新开始
    refreshStart(done) {
      console.log("onPulldown");
      // this.infoList = [];
      window.scrollTo(0, this.scrollTopHeight);
      // document.querySelector("#infoStream").scrollTop = 100;
      this.getInfoList(true, done);
    },
    // 加载开始
    loadStart(done) {
      this.getInfoList(false, done);
    },
    handleScroll(e) {
      if (!this.tableLoading) {
        let body = document.querySelector(".my-scrollbar");

        console.log(e, body.scrollTop, body.offsetHeight);
        // console.log(window.innerHeight);
        if (
          // body.scrollTop + window.innerHeight + 1000 >= body.offsetHeight &&
          (body.scrollTop + window.innerHeight) / body.offsetHeight >= 0.99 &&
          e.wheelDeltaY < 0
        ) {
          this.$refs.scroll.trigger("load");
          this.tableLoading = true;
        }
        if (body.scrollTop <= 0 && e.wheelDeltaY > 0) {
          // this.$refs.scroll.trigger("refresh");
        }
      }
    },
    loop(done) {
      setTimeout(() => {
        const temp = [];
        for (let i = 0; i < this.once; i++) {
          if (this.countOfRender >= this.total) {
            this.tableLoading = false;
            if (done) {
              done();
            }
            return;
          } else {
            temp.push(this.data[this.countOfRender]);
            this.countOfRender++;
          }
        }
        this.infoList = this.infoList.concat(temp);
        this.loop();
        // this.tableLoading = false;
      }, 700);
    },
    classChange(event) {
      console.log(event);
      this.no_race = event ? "1" : "";
      localStorage.setItem(this.userStore.user_id + "_no_race", this.no_race);

      this.$refs.scroll.trigger("refresh");
    },
  },
  created() {
    // this.getInfoList(true);
    const user = localStorage.getItem(this.userStore.user_id + "_no_race");
    this.no_race = user || "";
    window.addEventListener("wheel", this.handleScroll);
  },
  mounted() {
    this.$nextTick(() => {
      this.tab = this.tagId || (this.stockId == "" && this.tagId == "") ? 0 : 1;
      this.changeTab(this.tab);
      this.$refs.scroll.trigger("refresh");
    });
    setTimeout(() => {
      this.scrollTopHeight =
        document.querySelector("#infoStream").offsetTop - 72;
      console.log(this.scrollTopHeight);
    }, 2000);
  },
  computed: {},
  watch: {},
  directives: {},
  filters: {},
  props: {
    tagId: {
      default: "",
      type: String,
    },
    stockId: {
      default: "",
      type: String,
    },
  },
  destroyed() {
    window.removeEventListener("wheel", this.handleScroll);
  },
};
</script>

<style>
.desc {
  line-height: 20px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn.v-item--active {
  font-weight: 600;
  color: #2a66f6 !important;
  border-bottom: 1px solid #2a66f6 !important;
  border-radius: none;
  font-weight: bold;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn.v-zc-item--active {
  color: #2a66f6 !important;
  border-radius: 2px !important;
  margin-bottom: 6px !important;
  background: #f0f7ff;
  font-weight: bold;
}
.theme--light.v-btn-toggle {
  border-radius: 0 !important;
}
.theme--light.v-btn-toggle.zc-toggle .v-btn.v-btn--text {
  height: 20px;
}
.zc-tag {
  height: 16px;
  padding: 0 3px;
  border: 1px solid #ffd2a6;
  color: #f67c2a;
  font-size: 10px;
  margin-left: 6px;
  border-radius: 2px;
  word-break: keep-all;
}
.v-application .info--text {
  color: #2196f3 !important;
  caret-color: #2196f3 !important;
}
</style>
